var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_vm._v(" You have no access to " + _vm._s(_vm.id) + ". Please contact the support to resolve the issue ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }