<template>
  <div class="animated fadeIn">
    You have no access to {{ id }}. Please contact the support to resolve the
    issue
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "Route name"
    }
  },
  name: "AccessDenided",
  components: {},
  data: function() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped></style>
